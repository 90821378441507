<template>
  <div
    v-if="!item"
    style="
      width: 100%;
      height: 100%;
      border: 1px solid var(--theme-highlight);
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
    "
  >
    <hub-icon name="loading" spin></hub-icon>
  </div>
  <div
    v-else-if="item.isFailed"
    style="
      width: 100%;
      height: 100%;
      border: 1px solid var(--theme-highlight);
      border-radius: 3px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      flex-direction: column;
    "
  >
    Unable to load list.
    <br />
    <hub-button variant="icon" @click="$emit('remove', id)"><hub-icon name="trash-can"></hub-icon></hub-button>
  </div>
  <div v-else class="list-with-autoload">
    <header class="drag-handle">
      <label>{{ item.title }} {{ currentFilter.deleted ? '(DELETED)' : '' }} </label>
      <div>
        <span v-if="item.total > -1">
          <hub-icon v-if="item.isPending && item.total" size="sm" name="loading" spin></hub-icon>{{ item.data.length }} of {{ item.total }}</span
        >
        <slot name="actions" :item="item"></slot>
      </div>
    </header>
    <div ref="streamRootRef" style="overflow-y: scroll">
      <transition-group name="task-list" class="list" tag="ul">
        <li v-for="row in item.data" :key="row.id" class="component-wrapper">
          <component
            :is="getComponent(item.layout)"
            :class="[
              ...($route.params.id === row.id ? ['active'] : []),
              ...(selectable ? ['selectable'] : []),
              ...(selectedTasks.find(t => t.id === row.id) ? ['selected'] : []),
              ...(expandable ? ['expandable'] : []),
              ...(editedTask && editedTask.id === row.id ? ['expanded'] : [])
            ]"
            v-bind="row"
            @click="$e => open($e, row)"
            @dblclick.stop="$e => $emit('open', row)"
          ></component>
          <div v-if="editedTask && editedTask.id === row.id" class="task-info">
            <div v-if="isSaving" class="blocker">
              <hub-icon title="add me" spin name="content-save" />
            </div>
            <div class="row">
              <div class="buttons-wrapper">
                <TaskStatusDropdown
                  class="task-status"
                  :task="editedTaskInstance"
                  :with-status-text="true"
                  @status-changed="$e => onStatusChange($e)"
                />

                <div>
                  <hub-button v-if="hasChanges" variant="icon" @click="saveTaskChanges">
                    <hub-icon title="save changes" name="content-save" />
                  </hub-button>
                  <hub-button variant="icon" title="discard changes" @click="toggleEditTask(row)">
                    <hub-icon name="close" />
                  </hub-button>
                  <hub-button variant="icon" title="open task modal" @click="$emit('open', row)">
                    <hub-icon name="fit-to-page-outline" />
                  </hub-button>
                </div>
              </div>
            </div>
            <div v-if="editedTask.workflow.milestoneTitle" class="row">Milestone: {{ editedTask.workflow.milestoneTitle }}</div>
            <div class="row">
              <TextField v-model="editedTask.title" placeholder="Task title" />
            </div>
            <div class="row">
              <DateField v-model:value="editedTask.dueAt" />
            </div>
            <div class="row">
              <hub-button v-if="!editedTask.assignees.includes(email)" variant="icon" class="addme-button" @click="addMe">
                <hub-icon title="add me" name="account-plus-outline" />
              </hub-button>
              <Assignees v-model:value="editedTask.assignees" :shorthand="true" placeholder="Task assignees" />
            </div>
            <div class="row">
              <Editor v-model="editedTask.notes" :excluded-functions="['blockquote', 'heading']" />
            </div>
          </div>
        </li>
      </transition-group>
      <hub-observe v-if="!item.isPending && item.total > item.data.length" :get-root-ref="() => $refs['streamRootRef']" @intersect="more">
      </hub-observe>
      <div v-if="item.isPending" style="padding: 0.5rem 0; display: flex; justify-content: center; align-items: center">
        <hub-icon name="loading" spin></hub-icon>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { createApp, h } from 'vue';

import Icon from '@/components/common/Icon';
import Button from '@/components/common/Button';
import Observe from '@/components/inventions/Observer';
import TaskCard from '@/components/search/TaskCard';
import TaskCardCompact from '@/components/search/TaskCardCompact';
import TaskCardMinimalistic from '@/components/search/TaskCardMinimalistic';
import TaskCardWithRelativeDueAt from '@/components/search/TaskCardWithRelativeDueAt';
import TaskCardWithAssignee from '@/components/search/TaskCardWithAssignee';
import TaskCardWithNotes from '@/components/search/TaskCardWithNotes';
import TaskCardWithAssigneesAndTime from '@/components/search/TaskCardWithAssigneesAndTime';
import ToastService from '@/plugins/ToastService';

import TextField from '@/components/common/TextField';
import Assignees from '@/components/Assignees';
import Editor from '@/components/common/editor/Editor';
import DateField from '@/components/common/DateField';

import MetadataModal from '@/components/inventions/tasks/MetadataForm.vue';
import TaskStatusDropdown from '@/components/common/TaskStatusDropdown';
/* eslint-disable vue/one-component-per-file */

export default {
  components: {
    'hub-button': Button,
    'hub-icon': Icon,
    'hub-observe': Observe,
    TextField,
    Assignees,
    Editor,
    DateField,
    TaskStatusDropdown
  },
  props: {
    id: {
      type: String,
      required: true
    },
    selectable: {
      type: Boolean,
      default: true
    },
    selectedTasks: {
      type: Array,
      default: () => []
    },
    autoRefresh: {
      type: Boolean,
      default: false
    },
    expandable: {
      type: Boolean,
      default: false
    }
  },
  emits: ['open', 'remove', 'taskToggled', 'edited'],
  data() {
    return {
      isReady: false,
      timeoutRef: null,
      isRefreshing: false,
      editedTask: null,
      editedTaskInstance: null,
      isSaving: false
    };
  },
  computed: {
    ...mapState({
      email: s => s.identity.email,
      item(s) {
        return s.boards.lists.collection.find(item => item.id === this.id);
      },
      milestoneTemplates: s => s.milestones.templates
    }),
    ...mapGetters({
      filter: 'filters/byId'
    }),
    currentFilter() {
      if (!this.item) {
        return;
      }
      return this.filter(this.item.id);
    },
    hasChanges() {
      if (!this.editedTask) {
        return false;
      }
      return (
        this.editedTask.title !== this.editedTaskInstance.title ||
        this.editedTask.notes !== this.editedTaskInstance.notes ||
        this.editedTask.assignees.length !== this.editedTaskInstance.assignees.length ||
        this.editedTaskInstance.assignees.some(assignee => !this.editedTask.assignees.includes(assignee)) ||
        +new Date(this.editedTaskInstance.dueAt) !== +new Date(this.editedTask.dueAt)
      );
    }
  },
  watch: {
    async currentFilter(n, o) {
      if (!this.isReady) {
        return;
      }
      if (o && n && o.updatedAt === n.updatedAt) {
        return;
      }

      await this.$store.dispatch('boards/lists/getById', { id: this.id });
      await this.$store.dispatch('boards/lists/exec', { id: this.item.id, skip: this.item.data.length, size: this.item.data.length, skip: 0 });
    }
  },
  async created() {
    await this.$store.dispatch('boards/lists/getById', { id: this.id });
    this.$store.dispatch('boards/lists/exec', { id: this.id, skip: 0 });
    this.isReady = true;
    if (this.autoRefresh) {
      this.executeRefreshTimeout();
      document.addEventListener('visibilitychange', this.subscribeToVisibilityChange);
    }
  },
  unmounted() {
    clearTimeout(this.timeoutRef);
    document.removeEventListener('visibilitychange', this.subscribeToVisibilityChange);
  },
  methods: {
    async subscribeToVisibilityChange() {
      if (document.hidden) {
        if (!this.isRefreshing) {
          clearTimeout(this.timeoutRef);
        }
      } else {
        await this.refresh();
        this.executeRefreshTimeout();
      }
    },
    executeRefreshTimeout() {
      this.timeoutRef = setTimeout(async () => {
        if (!this.isRefreshing) {
          this.isRefreshing = true;
          await this.refresh();
          this.isRefreshing = false;
          if (!document.hidden) {
            this.executeRefreshTimeout();
          }
        }
      }, 60 * 1000);
    },
    async refresh() {
      await this.$store.dispatch('boards/lists/exec', { id: this.id, skip: 0, size: this.item.data.length, refresh: true });
    },
    more() {
      this.$store.dispatch('boards/lists/exec', { id: this.item.id, skip: this.item.data.length });
      this.$trackEvent(`Task list virtual scroll applied`);
    },
    open($e, ...args) {
      if ($e.metaKey || $e.ctrlKey) {
        this.$emit('taskToggled', args[0]);
        return;
      }
      if (this.expandable) {
        this.toggleEditTask(args[0]);
      }
    },
    getComponent(layout) {
      switch (layout) {
        case 'compact':
          return TaskCardCompact;
        case 'minimalistic':
          return TaskCardMinimalistic;
        case 'relative':
          return TaskCardWithRelativeDueAt;
        case 'defaultwithassignees':
          return TaskCardWithAssignee;
        case 'defaultwithassigneesandtime':
          return TaskCardWithAssigneesAndTime;
        case 'defaultwithnotes':
          return TaskCardWithNotes;
        default:
          return TaskCard;
      }
    },
    async toggleEditTask(data) {
      if (this.editedTask && this.editedTask.id === data.id) {
        this.editedTask = null;
        return;
      }
      if (data.workflow && data.workflow.id) {
        await this.$store.dispatch('milestones/bulkGetTemplates', [data.workflow.id]);
      }
      this.editedTask = {
        id: data.id,
        dueAt: data.dueAt,
        assignees: [...data.assignees],
        title: data.title,
        notes: data.notes,
        status: data.status,
        workflow: data.workflow,
        type: data.type
      };
      this.editedTaskInstance = data;
    },
    async saveTaskChanges(formData) {
      this.isSaving = true;
      const newTask = await this.$store.dispatch('tasks/update', {
        ...this.editedTaskInstance,
        ...this.editedTask,
        formData: formData || {}
      });
      await this.refresh();
      this.editedTaskInstance = newTask;
      this.isSaving = false;
    },
    async onStatusChange(newStatus) {
      let metadata = null;
      let form;
      let formData;
      const workflowTemplate = this.milestoneTemplates[this.editedTaskInstance.workflow.id];
      if (workflowTemplate) {
        if (workflowTemplate.isRequestPending || workflowTemplate.isRequestFailed) {
          this.$toast.error({
            title: 'Page didn`t load correctly.',
            message: `Please, try again later or contact our development team.`
          });
        }
        if (workflowTemplate.template) {
          const milestoneTemplate = workflowTemplate.template.find(template => template.id === this.editedTaskInstance.workflow.milestoneTemplateId);
          if (milestoneTemplate) {
            const path = milestoneTemplate.paths.find(path => {
              return path.from.step === this.editedTaskInstance.workflow.stepId && path.from.port === newStatus;
            });
            if (path && path.from.action && path.from.action.form) {
              form = path.from.action && path.from.action.form;
              const formValues = await this.$store.dispatch('steps/getForm', {
                template: form.properties,
                model: { thisTask: this.editedTaskInstance },
                context: {
                  inventionId: this.editedTaskInstance.invention.inventionId || this.editedTaskInstance.invention.id,
                  milestoneId: this.editedTaskInstance.workflow.milestoneId
                }
              });

              metadata = await this.requestMetadata({ title: form.name, properties: formValues }, this.editedTaskInstance);
              if (!metadata) {
                this.isRequestPending = false;
                return;
              }

              formData = {
                name: form.name,
                properties: metadata
              };
            }
          }
        }
      }
      this.editedTask.status = newStatus;
      await this.saveTaskChanges(formData);
    },
    async requestMetadata(form, task) {
      const self = this;
      return new Promise(resolve => {
        const closeWithResult = result => {
          instance.unmount();
          resolve(result);
        };
        const instance = createApp({
          render() {
            return h(MetadataModal, {
              form,
              task,
              onClose() {
                closeWithResult();
              },
              onSubmit(result) {
                closeWithResult(result);
              }
            });
          }
        });

        instance.use(self.$store);
        instance.use(ToastService);
        instance.mount(document.getElementById('modal'));
      });
    },
    addMe() {
      this.editedTask.assignees = [...this.editedTask.assignees, this.email];
    }
  }
};
</script>

<style lang="scss" scoped>
.list-with-autoload {
  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: max-content minmax(0, 1fr);
  height: 100%;
  width: 100%;
  background: var(--theme-surface);

  header {
    padding: 0.65rem var(--theme-scroll-width) 0.45rem;
    border-radius: 3px;
    border-bottom: 1px solid var(--theme-highlight);
    margin-bottom: 0.25rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    > label {
      font-size: 1rem;
      /* text-transform: uppercase; */
      font-weight: 700;
      color: var(--theme-on-background-accent);
    }

    span {
      font-size: 0.8rem;
      font-weight: 500;
      color: var(--theme-on-background-accent);
      display: inline-flex;
      align-items: center;
      position: absolute;
      width: auto;
      bottom: -12px;
      right: 0;
      background-color: var(--theme-surface);
      padding: 4px 10px;
      height: 24px;

      i {
        margin: 0.25rem;
      }
    }
  }

  .list {
    padding-left: var(--theme-scroll-width);
    li {
      border-bottom: 1px solid var(--theme-highlight);
      margin-bottom: 0;
      &:hover,
      &.selected,
      &.expanded {
        box-shadow: none;
        background-color: var(--theme-background);
      }
    }

    .task-status {
      display: flex;
      align-items: center;
      span {
        margin-left: 10px;
      }
      .disabled {
        opacity: 0.6;
      }
      .title {
        color: var(--theme-primary);
        text-transform: uppercase;
      }
    }
    .task-info {
      position: relative;
      .blocker {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--theme-on-primary);
        z-index: 11;
      }
      .buttons-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      background-color: var(--theme-background);
      padding: 5px;
      .row {
        margin-bottom: 10px;
        display: grid;
        grid-template-columns: 1fr minmax(0, max-content);
        position: relative;
        & > label {
          font-weight: 500;
          font-size: 0.75rem;
          letter-spacing: 0.025em;
        }
        .addme-button {
          position: absolute;
          z-index: 10;
          right: 25px;
          top: 2px;
        }
      }
    }

    .task-list-move,
    .task-list-enter-active,
    .task-list-leave-active {
      transition: all 0.5s ease;
    }

    .task-list-enter-from,
    .task-list-leave-to {
      opacity: 0;
      transform: translateX(50px);
    }
    .task-list-leave-active {
      position: absolute;
    }
  }
}
</style>
